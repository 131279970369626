<template>
  <v-container fluid class="pa-0">
    <v-card flat class="mx-auto" max-width="1200">
      <v-card-title class="d-flex justify-space-between align-center py-4">
        <h1 class="text-h4 font-weight-bold">Vendors</h1>
        <v-btn color="primary" @click="openDialog" elevation="2">
          <v-icon left>mdi-plus</v-icon>
          Add Vendor
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="vendors"
          :loading="loading"
          :search="search"
          class="elevation-0"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Search vendors"
              prepend-inner-icon="mdi-magnify"
              class="mx-4 mt-4"
              outlined
              dense
              hide-details
            ></v-text-field>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn icon small @click="editItem(item)">
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>

          <template v-slot:item.estado="{ item }">
            <v-chip :color="getStatusColor(item.estado)" small label text-color="white">
              {{ item.estado }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- Create/Edit Dialog -->
    <v-dialog v-model="dialog" max-width="700px">
      <v-card>
        <v-card-title
          class="headline grey lighten-2 px-4 py-3 d-flex justify-space-between"
        >
          {{ formTitle }}
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pa-4">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="editedItem.codigo"
                    :rules="[(v) => !!v || 'Code is required']"
                    hide-details
                    label="Code"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="editedItem.cedula"
                    :rules="[(v) => !!v || 'ID is required']"
                    label="ID"
                    hide-details
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="editedItem.nombre"
                    :rules="[(v) => !!v || 'Name is required']"
                    label="Name"
                    hide-details
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="editedItem.celular"
                    :rules="[(v) => !v || /^[0-9]+$/.test(v) || 'Phone must be valid']"
                    label="Phone"
                    outlined
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    v-model="editedItem.email"
                    :rules="[(v) => !v || /.+@.+\..+/.test(v) || 'E-mail must be valid']"
                    label="Email"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-select
                    v-model="editedItem.estado"
                    :items="statusOptions"
                    :rules="[(v) => !!v || 'Status is required']"
                    label="Status"
                    hide-details
                    required
                    outlined
                    dense
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
          <v-btn color="blue darken-1" :disabled="!valid" :loading="saving" @click="save">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Snackbar for notifications -->
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { webserver } from "../services/webserver.js";

export default {
  data: () => ({
    dialog: false,
    loading: false,
    saving: false,
    search: "",
    valid: true,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "success",
    headers: [
      { text: "Store", value: "store" },
      { text: "Code", value: "codigo" },
      { text: "Name", value: "nombre" },
      { text: "Status", value: "estado" },
      { text: "Email", value: "email" },
      { text: "Phone", value: "celular" },
      { text: "Actions", value: "actions", sortable: false, align: "end" },
    ],
    statusOptions: ["Activo", "Inactivo", "Pendiente"],
    vendors: [],
    editedIndex: -1,
    editedItem: {
      store: "",
      codigo: "",
      cedula: "",
      nombre: "",
      estado: "",
      email: "",
      celular: "",
    },
    defaultItem: {
      store: "",
      codigo: "",
      cedula: "",
      nombre: "",
      estado: "Activo",
      email: "",
      celular: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Vendor" : "Edit Vendor";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.get_vendors();
  },

  methods: {
    async get_vendors() {
      var qry = {
        store: window.store.store_id,
        dbSearch: "inventory",
        filters: [
          {
            field: "id_pos",
            operator: "=",
            value: window.store.store_id,
          },
        ],
      };
      this.loading = true;
      let promise = new Promise((resolve, reject) => {
        webserver(
          "vendors",
          qry,
          function (data) {
            resolve(data);
          },
          function () {
            reject([]);
          }
        );
      });

      var result = await promise;
      this.loading = false;
      if (result.length > 0) {
        this.vendors = result;
      } else {
        this.vendors = [];
      }
    },

    editItem(item) {
      this.editedIndex = this.vendors.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    openDialog() {
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      delete this.editedItem.qty;
      this.editedItem.store = window.store.store_id;
      this.saving = true;
      var qry = {
        store: window.store.store_id,
        data: [],
      };
      qry.data.push(this.editedItem);
      console.log("sAVE", qry);
      let promise = new Promise((resolve, reject) => {
        webserver(
          "vendors_update",
          qry,
          function (data) {
            resolve(data);
          },
          function () {
            reject([]);
          }
        );
      });

      var result = await promise;
      this.saving = false;
      console.log(result);
      if (result.length > 0) {
        this.showSnackbar("Vendor saved successfully", "success");
        this.dialog = false;
      } else {
        this.showSnackbar("Error saving vendor", "error");
      }
      this.get_vendors();
    },

    getStatusColor(status) {
      if (status === "Activo") return "green";
      if (status === "Inactivo") return "grey";
      return "orange"; // Para 'Pending' u otros estados
    },

    showSnackbar(text, color) {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    },
  },
};
</script>

<style scoped>
.v-data-table ::v-deep .v-data-table__wrapper {
  overflow-x: auto;
}

.v-data-table ::v-deep table {
  border-collapse: separate;
  border-spacing: 0 8px;
}

.v-data-table ::v-deep tbody tr {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease-in-out;
}

.v-data-table ::v-deep tbody tr:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.v-data-table ::v-deep td {
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}

.v-data-table ::v-deep td:first-child {
  border-left: 1px solid #f0f0f0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.v-data-table ::v-deep td:last-child {
  border-right: 1px solid #f0f0f0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
</style>
